@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
    max-width: 60vw;
    overflow: auto;
}

code.hljs {
    border-radius: .25rem;
    white-space: pre-wrap;
}

/* Tilt_Prism - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'ShareTech';
    font-style: normal;
    font-weight: 400;
    src: local("ShareTech"), url('./fonts/Share_Tech/ShareTech-Regular.ttf') format('truetype') /* Safari, Android, iOS */
}

.logo {
    font-family: 'ShareTech';
}

/**
Editor.js
 */
.ce-settings, .ce-toolbox, .ce-toolbar {
    display: none;
}

.ce-block__content, .codex-editor {
    position: static;
}

.ce-paragraph {
    position: relative;
    padding-left: 0.5rem;
    margin-left: -0.625rem;
    border-left-width: 0.125rem;
    border-left-color: transparent;
}

.ce-paragraph:after {
    transition: all 300ms;
    opacity: 0;
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
    color: rgb(156 163 175);
    content: attr(data-after);
    position: absolute;
    bottom: -0.5rem;
    right: 0;
}

.ce-paragraph--focused:after {
    opacity: 100%;
}

.ce-paragraph--negative {
    color: rgb(220 38 38);
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    font-weight: 500;
}

.ce-paragraph--invalid {
    border-left-color: rgb(248 113 113);
}

.ce-paragraph--invalid:after {
    color: rgb(248 113 113);
}

.ce-block--focused .ce-paragraph:after {
    opacity: 100%;
}

.ce-paragraph[data-placeholder]:empty::before {
    content: attr(data-placeholder);
    color: #707684;
    font-weight: normal;
    opacity: 0;
}

.codex-editor__redactor {
    margin-right: 0 !important;
}

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
    opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty:focus::before {
    opacity: 0;
}

.ce-paragraph p:first-of-type {
    margin-top: 0;
}

.ce-paragraph p:last-of-type {
    margin-bottom: 0;
}

/**
Editor.js END
 */

/* General scrollbar styling */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
    /*background: rgb(55, 65, 81);*/
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
    background: rgb(209 213 219);
    border-radius: 4px;
}

/* Scrollbar corner */
::-webkit-scrollbar-corner {
    background: transparent;
}

/* Firefox scrollbar */
* {
    scrollbar-width: thin;
    scrollbar-color: rgb(209 213 219) transparent;
    /*scrollbar-color: rgb(31, 41, 55) rgb(55, 65, 81);*/

}


/* thinking message animation */

:root {
    --angle: 45deg;
    --opacity: 1;
}

.rainbow {
    border-style: dotted;
    border-color: transparent;
    background-image: linear-gradient(
            to right,
            rgb(75 85 99),
            rgb(75 85 99)
    ),
    conic-gradient(
            from var(--angle),
            #266AA6 0deg 90deg,
            #07131D 0deg 180deg,
            #266AA6 180deg 360deg,
            #07131D 270deg 360deg
    );
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.rainbow-dark {
    border-style: dotted;
    border-color: transparent;
    background-image: linear-gradient(
            to right,
            rgb(75 85 99),
            rgb(75 85 99)
    ),
    conic-gradient(
            from var(--angle),
            #266AA6 0deg 90deg,
            #07131D 0deg 180deg,
            #266AA6 180deg 360deg,
            #07131D 270deg 360deg
    );
    background-origin: border-box;
    background-clip: padding-box, border-box;
}


/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
    @property --opacity {
        syntax: "<number>";
        initial-value: 1;
        inherits: false;
    }

    @property --angle {
        syntax: "<angle>";
        initial-value: 0deg;
        inherits: false;
    }

    @keyframes opacityChange {
        to {
            --opacity: 1;
        }
    }

    @keyframes rotate {
        to {
            --angle: 360deg;
        }
    }

    .rainbow-dark, .rainbow {
        animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
    }
}
